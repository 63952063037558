/**
 * Initialize cart.
 *
 * Copy values from source flow
 *
 * In case there are no passengers, products or offers,
 * the navigation is aborted.
 */
export default defineNuxtRouteMiddleware(() => {
    const flowStore = useFlowStore();
    const cartStore = useCartStore();

    // Currently, if there is an active "flow", copy products, offers and passengers from there
    // In the future, other sources might be added that the cart can be initialized from
    if (flowStore.flow) {
        const shopStore = useShopStore();
        const passengerStore = usePassengerStore();
        cartStore.offers = JSON.parse(JSON.stringify(shopStore.offers));
        cartStore.products = JSON.parse(JSON.stringify(shopStore.products));
        cartStore.passengers = JSON.parse(JSON.stringify(passengerStore.passengers));
        cartStore.lastFlow = flowStore.flow;
    }

    // in case there are no passengers, products or offers, abort the navigation
    if (
        !cartStore.passengers.length
        || !Object.keys(cartStore.products).length
        || !Object.keys(cartStore.offers).length
    ) {
        return navigateTo(getFirstFlowRoute(flowStore.flow?.steps));
    }
});
